/**   
 * api接口统一管理
 */
 import {
    get,
    post
  } from '../request'
//首页数据
  export const indexApi = data => post("/api/init", data)
  
// 首页底部详情数据
  export const indexDetailApi = data => post("/api/selectBypoiType", data)
  
// marker详情页数据数据
  export const markerDetailApi = data => post("/api/selectPoiById", data)
  
//专题接口数据
  export const indexTitleApi = data => post("/api/title/init", data)
  
//全域景区路线
  export const allLineApi = data => post("/api/selectLineAll", data)
  
//搜索全域数据
  export const searchApi = data => post("/api/selectByAll", data)
  
//搜索景点数据
  export const searchPoiApi = data => post("/api/selectPoiByScenicId", data)

//景区数据请求
  export const scenicDataApi = data => post("/api/selectScenicById", data)
  //语言选择
  export const languageDataApi = data => post("/api/initLanguage", data)
  //标签语言
  export const tagLanApi = data => post("/api/getRightOrLeft", data)
  //专题的浏览量
  export const spEyeApi = data => post("/api/addTitleCouns", data)
    //专题的浏览量
 export const lineEyeApi = data => post("/api/addLineCounts", data)
  //资讯
  export const noticeApi = data => post("/api/wxAnnounce", data)
  //导览页接口
  export const navApi = data => post("/api/wxTutorials", data)
  //周边数据请求
  export const roundSearchApi = poi => get("/v3/place/around?key=7c9365cd253bea64d58e9a35a7bf0964&location="+poi+'&radius=10000&types=010100|200300')